/**
 *
 */
export default defineNuxtRouteMiddleware((to) => {
    const localeRoute = useLocaleRoute();
    const esavStore = useEsavStore();

    if (!esavStore.canRefund) {
        return navigateTo(localeRoute({
            name: 'esav',
            params: { bookingId: to.params.bookingId },
        }));
    }
});
